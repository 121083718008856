import {Config} from "~/@types/config";

const config: Config = {
    routes: {
        index: {
            path: "/",
            title: "Main page",
            editable: false
        },
        account: {
            title: "Account",
            editable: false
        },
        about: {
            title: "About",
            editable: false
        },
        docs: {
            title: "Docs",
            editable: false,
            children: {
                reference: {"title": "API References"},
                guides: {
                    "title": "Guides",
                    splat: true
                },
                changelog: {"title": "Changelog"}
            }
        },
        api: {
            title: "API",
            editable: false
        },
        blog: {
            title: "Blog",
            editable: false
        },
        login: {
            title: "Login",
            editable: false
        },
        signup: {
            title: "Signup",
            editable: false
        },
        sandbox: {
            title: "Sandbox",
            editable: false
        },
        "*": {
            title: "Unknown page",
            template: "404"
        }
    }
};

export default config;