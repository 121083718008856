import {configureStore} from "@reduxjs/toolkit";
import {baseAPI} from "~/api/baseAPI";
// import {setupListeners} from '@reduxjs/toolkit/query';
import rootReducer from "~/reducers";
import {useDispatch, useSelector} from "react-redux";

// import offline from '~/enhancers/offline';

const apiMiddlewares = [
    baseAPI.middleware,
];

const store = configureStore(
    {
        reducer: rootReducer,
        // enhancers: getDefaultEnhancers => getDefaultEnhancers().concat(offline),
        middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(apiMiddlewares),
    }
);

export const useAppDispatch = () => useDispatch<typeof store.dispatch>();
export const useAppState = () => useSelector((state: RootState)=>state);

export type RootState = ReturnType<typeof rootReducer>
// setupListeners(store.dispatch);

export default store;