import md5 from "md5";

export function abbrStr(string: string): string {
    return string.replace(/[eyuioa]/g, "").replace(/(.)\1+/gi, "$1")
}

export function sanitizePath(pathString: string): string {
    return pathString.replace(/(([^:]|^)\/)\/+/g, "$1").replace(/\/$/g, "") || "/"
}

export function getAPIEndpoint(path: string = "", env = process.env): string {
    return sanitizePath(`${env.API_ENDPOINT_HOST || ""}/${env.API_ENDPOINT_PATH || ""}/${path}`)
}

const existTokens: string[] = [];
export function uniqueToken(len: number = 5, recDepth: number = 20, prefix: string = ""): string {
    if (recDepth > 20) {
        recDepth = 20;
    }
    const hash = md5(`uni_${new Date().getTime()}_${Math.random()}`);
    if (len > hash.length) {
        len = hash.length;
    }
    const hashStart = randRange(0, hash.length - len);
    let token = "";
    if (prefix !== "") {
        token += prefix + "";
    }
    token += hash.slice(hashStart, hashStart + len);
    if (existTokens.indexOf(token) >= 0 && recDepth > 0) {
        token = uniqueToken(len, --recDepth, prefix);
    }
    return token;
}

export function capitalize(string: string, eachWord: boolean = true): string {
    const replRegExp = new RegExp("\\b\\w", eachWord ? "g" : "");
    return string ? string.replace(replRegExp, l => l.toUpperCase()) : string;
}

export function uncapitalize(string: string, eachWord: boolean = true): string {
    const replRegExp = new RegExp("\\b\\w", eachWord ? "g" : "");
    return string ? string.replace(replRegExp, l => l.toLowerCase()) : string;
}

export function randRange(min: number = 0, max: number = 9): number {
    if (max < min) {
        max = min;
    }
    min = Math.ceil(min);
    max = Math.floor(max);

    return Math.floor(Math.random() * (max - min + 1)) + min;
}