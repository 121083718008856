import {createSlice, createSelector} from "@reduxjs/toolkit";
import {capitalize} from "~/lib";
import {locationChange} from "~/reducers/app";
import {PageInitialState} from "~/@types/reducers/page";
import {PageTplNameTypeFixed} from "~/@types/components/templates/PageProps";

const initialState: PageInitialState = {
    slug: null,
    title: null,
};

export const pageSlice = createSlice({
    name: "page",
    initialState,
    reducers: {init: (state) => state},
    extraReducers: builder => {
        builder.addCase(
            locationChange,
            (state, action) => ({
                ...state,
                ...(action.payload.page || {}),
                slug: action.payload.slug
            })
        );
    },
    selectors: {
        getPage: state => state,
        getPageSlug: state => state.slug,
        getPageTemplates: createSelector(
            [
                state => state.template,
                state => state.slug,
            ],
            (template: PageTplNameTypeFixed, slug: string) => {
                const templates: (string | PageTplNameTypeFixed)[] = [];
                if (template) {
                    templates.push(template);
                }
                if (slug) {
                    templates.push(
                        slug,
                        slug.split("-").map(s => capitalize(s)).join("")
                    );
                }
                return templates;
            }
        ),
    },
});

export const {init} = pageSlice.actions;

export const {getPageTemplates, getPageSlug} = pageSlice.selectors;

export default pageSlice;