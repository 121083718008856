import {createSlice, isAnyOf} from "@reduxjs/toolkit";
import {userAPI} from "~/api/userAPI";
import {ACCOUNT_STATUS_INITIAL, ACCOUNT_STATUS_LOADED, ACCOUNT_STATUS_LOADING} from "~/constants/Account";
import {AccountInitialState} from "~/@types/reducers/account";

const initialState: AccountInitialState = {
    id: 0,
    status: null,
    username: undefined,
    "auth-token": VITE__API_DEVELOPER_TOKEN,
    "jwt-token": null,
    "restricted-pages": [],
};

export const accountSlice = createSlice({
    name: "account",
    initialState,
    reducers: {
        init: (state) => ({
            ...state,
            status: ACCOUNT_STATUS_INITIAL,
        }),
    },
    extraReducers: builder => {
        builder.addMatcher(
            isAnyOf(userAPI.endpoints.getUserByUserId.matchPending),
            (state, {meta: {arg: {originalArgs: userID}}}) => {
                return userID === "current"
                    ? {
                        ...state,
                        status: ACCOUNT_STATUS_LOADING
                    }
                    : state;
            }
        );
        builder.addMatcher(
            userAPI.endpoints.getUserByUserId.matchFulfilled,
            (state, {payload: user, meta: {arg: {originalArgs: userID}}}) => {
                return userID === "current"
                    ? {
                        ...state,
                        ...(user || {}),
                        status: ACCOUNT_STATUS_LOADED,
                    }
                    : state;
            }
        );
    },
    selectors: {
        getAccountStatus: state => state.status,
        isAnonymous: state => state.username === null,
        getRestrictedPages: state => state["restricted-pages"],
        getAuthToken: state => state["auth-token"],
        getJWTToken: state => state["jwt-token"]
    }
});

export const {init} = accountSlice.actions;

export const {
    getAccountStatus,
    getAuthToken,
    getJWTToken
} = accountSlice.selectors;

export default accountSlice;