import {createApi} from "@reduxjs/toolkit/query/react";
import {prepareFetchBaseQuery} from "~/lib";
import {OpenAPI3} from "openapi-typescript";

export const baseAPI  = createApi({
    baseQuery: prepareFetchBaseQuery(),
    endpoints: (build) => ({
        getSchema: build.query<OpenAPI3, void>(
            {query: () => ({ url: "/openapi.json?frontend=true&hide-gw=true" })}
        ),
    }),
});
export const {useGetSchemaQuery} = baseAPI;
