import {combineSlices} from "@reduxjs/toolkit";

import appSlice from "./app";
import accountSlice from "./account";
import navigationSlice from "./navigation";
import pageSlice from "./page";

import {userAPI} from "~/api/userAPI";
import {navigationAPI} from "~/api/navigationAPI";
import {docsAPI} from "~/api/docsAPI";
import {mediaAPI} from "~/api/mediaAPI";

export default combineSlices(
    userAPI,
    mediaAPI,
    navigationAPI,
    docsAPI,
    appSlice,
    accountSlice,
    navigationSlice,
    pageSlice
);