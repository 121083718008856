export const APP_STATUS_INITIAL = "initial";

export const APP_ALLOWED_CODE_LANGUAGES = [
    "shell",
    "javascript",
    "json",
    "xml",
    "www-form",
] as const;

export const APP_ALLOWED_CODE_PARSE_LANGUAGES = [
    "xml",
    "json",
    "javascript",
    "www-form",
] as const;

export const APP_ALLOWED_THEMES = [
    "default",
    "dark",
    "light",
] as const;